
export default {
  name: 'CdpContainer',
  props: {
    deviceType: {
      type: String,
      default: '',
    },
  },
  computed: {
    deviceClass() {
      if (this.deviceType === 'mobile') {
        return 'sm:hidden'
      }

      if (this.deviceType === 'desktop') {
        return 'hidden sm:block'
      }

      return ''
    },
  },
}
